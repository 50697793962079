import {Controller} from 'react-hook-form';
import {FormControl} from '@job-ish/ui/components';

import type {FormControlProps} from '@job-ish/ui/components';
import type {ReactNode} from 'react';
import type {
	Control,
	ControllerFieldState,
	ControllerRenderProps,
	FieldPath,
	FieldValues,
	RegisterOptions,
} from 'react-hook-form';

export const FormInput = <T extends FieldValues, K extends FieldPath<T>>({
	name,
	render,
	control,
	rules,
	labelPosition = 'left',
	...props
}: {
	name: K;
	render: (field: ControllerRenderProps<T, K>, fieldState: ControllerFieldState) => ReactNode;
	control: Control<T>;
	rules?: Omit<RegisterOptions<T, K>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
} & FormControlProps) => (
	<Controller
		control={control}
		name={name}
		render={({field, fieldState}) => (
			<FormControl
				{...props}
				errorMessage={fieldState.error?.message}
				id={name}
				invalid={!!fieldState.invalid}
				labelPosition={labelPosition}
			>
				{render(field, fieldState)}
			</FormControl>
		)}
		rules={rules}
	/>
);
