import {forwardRef} from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import Button from '../button';
import {dialogContentStyles, dialogOverlayStyles} from '../dialog/styles';
import Paper from '../paper';
import type {ButtonProps} from '../button';
import type {PaperVariantProps} from '../paper/styles';

export type AlertDialogTriggerProps = AlertDialogPrimitive.AlertDialogTriggerProps;
const AlertDialogTrigger = AlertDialogPrimitive.Trigger;

export type AlertDialogContentProps = Omit<AlertDialogPrimitive.AlertDialogContentProps, 'asChild'> &
	PaperVariantProps;
const AlertDialogContent = forwardRef<HTMLDivElement, AlertDialogContentProps>(
	({children, className, ...props}, forwardedRef) => (
		<AlertDialogPrimitive.Portal>
			<AlertDialogPrimitive.Overlay className={dialogOverlayStyles()} ref={forwardedRef} />
			<AlertDialogPrimitive.Content {...props} asChild ref={forwardedRef}>
				<Paper
					bordered
					className={twMerge(dialogContentStyles(), className)}
					enableMotion={false}
					padding="md"
					shadow
				>
					{children}
				</Paper>
			</AlertDialogPrimitive.Content>
		</AlertDialogPrimitive.Portal>
	),
);

export type AlertDialogTitleProps = AlertDialogPrimitive.AlertDialogTitleProps;
const AlertDialogTitle = forwardRef<HTMLHeadingElement, AlertDialogTitleProps>(
	({className, ...props}, forwardedRef) => (
		<AlertDialogPrimitive.Title
			{...props}
			className={twMerge('text-lg font-semibold text-mauve12', className)}
			ref={forwardedRef}
		/>
	),
);

export type AlertDialogDescriptionProps = AlertDialogPrimitive.AlertDialogDescriptionProps;
const AlertDialogDescription = forwardRef<HTMLDivElement, AlertDialogDescriptionProps>(
	({className, ...props}, forwardedRef) => (
		<AlertDialogPrimitive.Description
			{...props}
			className={twMerge('text-pretty text-mauve11', className)}
			ref={forwardedRef}
		/>
	),
);

export type AlertDialogCancelProps = Omit<AlertDialogPrimitive.AlertDialogCancelProps, 'asChild'> &
	Omit<ButtonProps, 'color'>;
const AlertDialogCancel = forwardRef<HTMLButtonElement, AlertDialogCancelProps>(
	({children, ...props}, forwardedRef) => (
		<AlertDialogPrimitive.Cancel {...props} asChild ref={forwardedRef}>
			<Button color="neutral">{children}</Button>
		</AlertDialogPrimitive.Cancel>
	),
);

export type AlertDialogActionProps = Omit<AlertDialogPrimitive.AlertDialogActionProps, 'asChild'> &
	Omit<ButtonProps, 'color'>;
const AlertDialogAction = forwardRef<HTMLButtonElement, AlertDialogActionProps>(
	({children, ...props}, forwardedRef) => (
		<AlertDialogPrimitive.Action {...props} asChild ref={forwardedRef}>
			<Button color="danger">{children}</Button>
		</AlertDialogPrimitive.Action>
	),
);

export type AlertDialogFooterProps = ComponentPropsWithoutRef<'div'>;
const AlertDialogFooter = forwardRef<HTMLDivElement, AlertDialogFooterProps>(
	({className, ...props}, forwardedRef) => (
		<div
			{...props}
			className={twMerge('mt-auto flex grow items-end justify-end gap-3 p-2 pt-4', className)}
			ref={forwardedRef}
		/>
	),
);

export type AlertDialogProps = AlertDialogPrimitive.AlertDialogProps;
const AlertDialog = assign((props: AlertDialogProps) => <AlertDialogPrimitive.Root {...props} />, {
	Trigger: AlertDialogTrigger,
	Content: AlertDialogContent,
	Title: AlertDialogTitle,
	Description: AlertDialogDescription,
	Footer: AlertDialogFooter,
	Cancel: AlertDialogCancel,
	Action: AlertDialogAction,
});

AlertDialog.Trigger.displayName = 'AlertDialog.Trigger';
AlertDialog.Content.displayName = 'AlertDialog.Content';
AlertDialog.Title.displayName = 'AlertDialog.Title';
AlertDialog.Description.displayName = 'AlertDialog.Description';
AlertDialog.Footer.displayName = 'AlertDialog.Footer';
AlertDialog.Cancel.displayName = 'AlertDialog.Cancel';
AlertDialog.Action.displayName = 'AlertDialog.Action';

export default AlertDialog;
